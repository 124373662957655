<template>
  <div class="home" title="发业财税">
    <!-- 表头 -->
    <Header />
    <!-- 轮播图 -->
    <van-swipe class="my-swipe" :autoplay="5000" indicator-color="red">
      <van-swipe-item v-for="item in bannerList" :key="item.id" @click="online_advice()">
        <img :src="item.image_url" width="100%" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- 九宫格导航 -->
    <van-grid :border="false">
      <van-grid-item
        v-for="item in GridLists"
        :key="item.id"
        @click="$router.push(item.hettpurl)"
      >
        <img :src="item.image" alt="" width="70" />
        <span>{{ item.name }}</span>
      </van-grid-item>
    </van-grid>
    <!-- 滑动区域 -->
    <div style="background-color: #fff; padding: 25px 0 25px 0">
      <van-swipe
        :width="350"
        :loop="false"
        :touchable="true"
        :show-indicators="false"
      >
        <van-swipe-item @click="online_advice()">
          <img
            src="../img/home/card01.png"
            alt=""
            width="300"
            style="padding-left: 15px"
          />
        </van-swipe-item>
        <van-swipe-item @click="online_advice()">
          <img src="../img/home/card02.png" alt="" width="300" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- TAB切换 -->
    <div class="TABbox">
      <div class="titleicon">
        <img src="../img/home/Ticon01.png" alt="" width="25px" height="25px" />
        <span>一站式工商服务</span>
      </div>
      <van-tabs >
        <van-tab title="工商服务">
          <div class="tabonebox" v-for="item in Tulists" :key="item.id">
            <div class="left">
              <div class="top">
                <img src="../img/home/lr.png" alt="">
                <span>{{item.title}}</span>
              </div>
              <div class="bottom">
                {{item.texl}}
              </div>
            </div>
            <div class="right">
               <img :src="item.imgurl" alt="" />
            </div>
          </div>
        </van-tab>
        <van-tab title="财税服务">
          <div class="tabonebox" v-for="item in Tulists" :key="item.id">
            <div class="left">
              <div class="top">
                <img src="../img/home/lr.png" alt="">
                <span>{{item.title}}</span>
              </div>
              <div class="bottom">
                {{item.texl}}
              </div>
            </div>
            <div class="right">
               <img :src="item.imgurl" alt="" />
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    </div>
    <!-- 服务流程 -->
    <div class="lcbox">
      <div class="titleicon">
        <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
        <span>服务流程</span>
      </div>
      <div class="imgbox">
        <img src="../img/home/lcbg.png" alt="" width="320" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 注册完成后拿到的资料 -->
    <div class="zczliao">
      <div class="titleicon">
        <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
        <span>注册完成后拿到的资料</span>
      </div>
      <van-grid :column-num="2">
        <van-grid-item >
          <img src="../img/home/tu07.png" alt="" width="160" height="160">
          <span>营业执照正副本</span>
        </van-grid-item>
        <van-grid-item >
          <img src="../img/home/tu08.png" alt="" width="160" height="160">
          <span>公司印章</span>
        </van-grid-item>
        <van-grid-item >
          <img src="../img/home/tu09.png" alt="" width="160" height="160">
          <span>公司章程</span>
        </van-grid-item>
        <van-grid-item >
          <img src="../img/home/tu10.png" alt="" width="160" height="160">
          <span>开业通知书</span>
        </van-grid-item> 
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 拿到执照后还需要办什么？ -->
    <div class="zzxbanli">
      <div class="titleicon">
        <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
        <span>拿到执照后还需要办什么？</span>
      </div>
      <div style="padding-top:10px; padding-bottom:10px">
        <div class="zzxbanli_item">
          <img src="../img/home/bg01.jpg" alt="" width="340px">
        </div>
        <div class="zzxbanli_item">
          <img src="../img/home/bg02.jpg" alt="" width="340px">
        </div>
        <div class="zzxbanli_item">
          <img src="../img/home/bg03.jpg" alt="" width="340px">
        </div>
        <div class="zzxbanli_item">
          <img src="../img/home/bg04.jpg" alt="" width="340px">
        </div>
        <div class="zzxbanli_item">
          <img src="../img/home/bg05.jpg" alt="" width="340px">
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 发业风采 -->
    <div class="fayefc">
      <div class="titleicon">
        <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
        <span>发业风采</span>
      </div>
      <div class="bottombg">
        <van-swipe class="my-swipe" >
         
          <van-swipe-item>
             <img src="../img/home/bg07.png" alt="" width="100%">
          </van-swipe-item>
         
        </van-swipe>
      </div>
      <div class="fatext">
       <span> 十多年服务 行业领先</span>
发业集团总部设立在广州珠江新城，是一家集工商注册、代理记账、广州入户、知识产权于一体的大型“企业一站式服务”集团。发业经过10多年的品牌积淀，在广大客户中建立了超卓信誉，并铸就出稳居中国企业服务行业前列的旗舰品牌。
      </div>
    </div>
    <div class="forubox">
        <span>广州市天河区珠江新城华穗路263号双城国际东座23层</span>
        <span>广州公司注册-广州发业财务咨询有限公司 ©1998版权所有.</span>
        <span class="text">联系电话:020-29878999 微信:18818878806  电话:18818878806</span>
    </div>
    <Fixd />
    <transition name="slide">
      <router-view />
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import { ConcatSource } from 'webpack-sources';
import Fixd from '@/components/Fixd.vue';
import Header from '@/components/header.vue';

// import { GitHomeData } from "@/request/api";
export default {
  name: "Home",
  data() {
    return {
      SearchValue: "",
      Tulists: [
        {
          id: 0,
          title:'公司注册',
          texl:'广州、南沙自贸区、香港等各类公司注册',
          imgurl: require("@/img/home/tu01.jpg"),
        },
        {
          id: 1,
          title:'分公司设立',
          texl:'公司规模扩大，开展异地业务，发业来给您解决',
          imgurl: require("@/img/home/tu02.jpg"),
        },
        {
          id: 2,
          title:'公司变更',
          texl:'公司法人、股东、地址等变更服务',
          imgurl: require("@/img/home/tu03.jpg"),
        },
        {
          id: 3,
          title:'地址挂靠',
          texl:'全市八区优质安全地址挂靠，无地址也能注册公司',
          imgurl: require("@/img/home/tu04.jpg"),
        },
        {
          id: 4,
          title:'许可证',
          texl:'各类特殊审批快速代办，食品经营许可证、道路运输许可证、进出口权等',
          imgurl: require("@/img/home/tu05.jpg"),
        },
        {
          id: 5,
          title:'公司起名、公司核名',
          texl:'不繁琐，不重名，助您100%注册成功',
          imgurl: require("@/img/home/tu06.jpg"),
        },
      ],
      bannerList: [
        {
          id: 0,
          image_url: require("@/img/home/banner.png"),
        },
        {
          id: 1,
          image_url: require("@/img/home/banner01.png"),
        },
         {
          id: 2,
          image_url: require("@/img/home/banner02.png"),
        },
         {
          id: 3,
          image_url: require("@/img/home/banner03.png"),
        },
      ],
      GridLists: [
        {
          id: 0,
          image: require("@/img/home/01.png"),
          name: "公司注册",
          hettpurl: "/Gszc",
        },
        {
          id: 1,
          image: require("@/img/home/02.png"),
          name: "AI智能起名",
          hettpurl: "/AI",
        },
        {
          id: 2,
          image: require("@/img/home/03.png"),
          name: "公司变更",
          hettpurl: "/Gsbg",
        },
        {
          id: 3,
          image: require("@/img/home/04.png"),
          name: "代理记账",
          hettpurl: "/Dljz",
        },
        {
          id: 4,
          image: require("@/img/home/05.png"),
          name: "许可证",
          hettpurl: "/Xkz",
        },
        {
          id: 5,
          image: require("@/img/home/06.png"),
          name: "公司注销",
          hettpurl: "/Gszx",
        },
        {
          id: 6,
          image: require("@/img/home/07.png"),
          name: "商标注册",
          hettpurl: "/Sbzc",
        },
        {
          id: 7,
          image: require("@/img/home/08.png"),
          name: "关于发业",
          hettpurl: "/Gyfy",
        },
      ],
    };
  },
  methods:{
    online_advice(){
      this.$GM.as()
    },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
    //  callPhone(phoneNumber) { 
    //     window.location.href = "tel://02029878999";
    //     console.log(phoneNumber)
    //   },
  },
  created() {
    
  
  },
  components: { Fixd, Header },
};
</script>
<style lang="scss" scoped>

.forubox{
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  background-color: #fff;
  padding: 20px 10px;
   padding-bottom: 70px;
  span{
    display: block;
    color: #828282;
    margin: 4px 0;
  }
  .text{
    color: #000;
  } 
}
.fayefc{
  margin-top: 10px;
  background-color: #fff;
  .bottombg{
    margin-top: 10px;
  }
  .fatext{
    padding: 10px;
    padding-bottom: 20px;
    font-size: 14px;
    font-family: "宋体";
    line-height: 20px;
    span{
      display: block;
      text-align: center;
    }
  }
}
.zzxbanli{
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 10px;
  .zzxbanli_item{
    display: flex;
    justify-content: center;
    padding: 10px 0 10px;
    img{
      background-size: 100%;
    }
  }
}
.zczliao{
  margin-top: 10px;
  padding-bottom: 15px;
  background-color: #fff;
  .van-grid-item{
    span{
      margin-top: 10px;
      font-size: 16px;
       font-weight: bold;
    font-family: "微软雅黑";
    }
  }
}
.lcbox {
  background-color: #fff;
  box-sizing: border-box;
  .imgbox {
    width: 100%;
    background-size: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    img {
     background-size: 100%;
    }
  }
}

.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.TABbox {
  margin-top: 20px;
  margin-bottom: 10px;
  ::v-deep .van-tabs__line {
    background-color: transparent;
  }
  ::v-deep .van-tab--active {
    background: url(../img/home/tabbg.png) center 2px no-repeat;
    background-size: 60%;
    color: #fff;
  }
  .tabonebox {
    padding: 10px 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    .left{
      width: 160px;
      padding-left: 10px;
      .top{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span{
          margin-left: 5px;
          font-size: 16px;
          color: #ed2222;
         
        }
      }
      .bottom{
        color: #858484;
        font-size: 12px;
         line-height: 16px;
          margin-top: 5px;
          padding-left: 10px;
      }
    }
    .right{
      width: 135px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

/* // 过渡开始之前的样式写在这里 */
.slide-enter,
.slide-leave-to {
  right: -100%;
}
/* // 过渡时的属性写在这里 */
.slide-enter-active,
.slide-leave-active {
  transition: right 0.2s;
}
/* // 过渡完成的样式写在这里 */
.slide-enter-to,
.slide-leave {
  right: 0;
}
</style>
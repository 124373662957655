export default {
    // kefu(){
    //     // return "<head>  <script>(function() {var _53code = document.createElement('script');_53code.src = 'https://tb.53kf.com/code/code/10160492/1';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(_53code, s);})();</script>  </head>"
    //     // var _53code = document.createElement("script");
    //     // _53code.src = "https://tb.53kf.com/code/code/10160492/1";
    //     // var s = document.getElementsByTagName("script")[0];
    //     // s.parentNode.insertBefore(_53code, s);
    // },
  
     as(){
        var _53api = $53.createApi();
        _53api.push('cmd', 'mtalk');
        _53api.query();
        console.log("ssss")
     },
    callPhone(phoneNumber) { 
        window.location.href = "tel://02029878999";
        console.log(phoneNumber)
    },
   

}
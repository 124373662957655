<template>
    <div>
        <div class="headerbox" >
            <img src="../assets/headtop.jpg" alt="" width="370" @click="Botell"/>
            <div class="btn">
                咨询热线<br>
                020-29878999
            </div>
            <!-- <div class="btn" @click="$router.push('/login')" v-if="cookie_name==''">
                登录
            </div>
            <div class="btn"  v-else>
                {{cookie_name}}
            </div> -->
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            cookie_name:'',
        };
    },
    created(){
        // setInterval(this.$GM.kefu(),2000);
        
        if(this.$cookies.isKey('token')==false){
        this.cookie_name=''
        }else{
        this.cookie_name=this.$cookies.get('token') 
        }
    },
    methods: {
        Botell(phoneNumber) {
            this.$GM.callPhone(phoneNumber)
        },
        ClickBtn(){
            console.log('1111111111')
        },
    },
};
</script>
<style lang="scss" scoped>
.headerbox{
    width: 100%;
    background-color: #d81a1a;
    position: relative;
    .btn{
       position: absolute;
    right: 15px;
    bottom: 17px;
    width: 110px;
    height: 30px;
    border-radius: 5px;
    /* background-color: #fff; */
    text-align: center;
    line-height: 19px;
    font-size: 15px;
    color: #fff;
    }
}
</style>